import Axios from "axios";

/*Axios.defaults.baseURL = 'https://api.github.com/users/';*/

const GithubService = Axios.create({
    baseUrl: "https://api.github.com/users/",
    headers: { 'Content-Type': 'application/json' }
})

export default {
    getAllInformations(){
        return GithubService.get('/spatrik2001')
            .then(response => { return response.data;})
            .catch(err => { console.log(err)});
    },
    getAllProjects(){
        return GithubService.get('/spatrik2001/repos')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
            })
    },
    getLatestRepositories() {
        return GithubService.get('/spatrik2001/repos?sort=pushed')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
            })
    },
    getAllFollowers(){
        return GithubService.get('/spatrik2001/followers')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
            })
    },
    getAllFollowing(){
        return GithubService.get('/spatrik2001/following')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
            })
    }
    
}